<template>
    <div class="portfolio_edit_textarea">
<!--        <el-input type="textarea" id="content" v-model="content"></el-input>-->
        <quill-editor v-model="content"></quill-editor>
        <div class="portfolio_edit_btn_wrap">
            <button class="portfolio_edit_btn confirm">{{ $t('yes') }}</button>
            <button class="portfolio_edit_btn cancel">{{ $t('cancel') }}</button>
        </div>
    </div>
</template>

<script>
import quillEditorMixins from "@/mixins/quillEditorMixins";

export default {
    name: "PortfolioWriteUpdateLayout",
    mixins: [quillEditorMixins],
    components: {},
    inject: {
        boardWriteSetData: {
            default: () => {
            }
        },
    },
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            content: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.initSetData();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initSetData() {
            this.seq = this.boardData.wr_seq;
            this.parentChange('wr_seq', this.seq);
            this.title = this.boardData.wr_subject;
            this.parentChange('title', this.title);
            this.content = this.boardData.wr_content
            this.content = util.htmlDecode(this.content);
            this.parentChange('content', this.content);
        },
        hasData() {
            return !util.isEmpty(this.boardData) && !util.isEmpty(this.boardData.wr_id);
        },
        parentChange(key, value) {
            this.boardWriteSetData(key, value)
        },
    },
    watch: {
        'content': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('content', this.content)
            },
        }
    },
}
</script>

<style scoped>

</style>
